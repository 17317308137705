import { BookingsGroup } from '../../types';
import { CreateActionParams } from '../actions';
import { getGroupCancellationFeeDetails } from '../../utils/bookInfo';
import { Policy } from '@wix/bookings-checkout-common-components';
import { Money } from '@wix/ambassador-bookings-fees-v1-booking-fee/types';

export type GetCancellationFeeInfo = ({
  group,
}: {
  group: BookingsGroup;
}) => void;

export function createGetCancellationFeeInfoAction({
  context,
  getControllerState,
}: CreateActionParams): GetCancellationFeeInfo {
  return async ({ group }) => {
    const [state, setState] = getControllerState();
    const { businessInfo } = state;
    const { api } = context;
    const groupFeeId = group?.groupId || group?.bookings[0]?.bookingId;
    const bookingIds = group.bookings?.map((booking) => booking.bookingId);

    const ListBookingsFees = await api.getListBookingFees({
      bookingIds,
    });

    if (!ListBookingsFees.bookingFees?.length) {
      setState({
        cancellationFeeInfo: {
          ...state.cancellationFeeInfo,
          [groupFeeId]: {
            policies: [],
            totalFee: {},
          },
        },
      });
      return;
    }

    const moneyList: Money[] = [];
    const policyList: Policy[] = [];
    ListBookingsFees?.bookingFees?.forEach((fee) => {
      const relevantBook = group.bookings.find(
        (booking) => booking.bookingId === fee.bookingId,
      );

      if (fee.cancellationFee?.price) {
        moneyList.push(fee.cancellationFee?.price);
      }

      policyList.push({
        policyDescription: fee.policyDetails?.description || '',
        serviceName: relevantBook?.service.name || '',
        serviceId: relevantBook?.service.id || '',
        policyId: fee.policyDetails?.id || '',
      });
    });

    const totalMoney = getGroupCancellationFeeDetails(
      moneyList,
      businessInfo?.dateRegionalSettingsLocale || '',
    )!;

    setState({
      cancellationFeeInfo: {
        ...state.cancellationFeeInfo,
        [groupFeeId]: {
          policies: policyList,
          totalFee: totalMoney,
        },
      },
    });
  };
}
